//@flow strict
import React from 'react';
import classNames from 'classnames/bind';
import styles from './Image.module.scss';

const cx = classNames.bind(styles);

type Props = {
  url: string;
  alt?: string;
};

const Image = ({url, alt}: Props) => {
  const imgAlt = alt || 'Image';
  return (
    <div className={cx('image')}>
      <img alt={imgAlt} src={url}/>
    </div>
  );
};

export default Image;