// @flow strict
import React, { Node as ReactNode } from 'react';
import styles from './ProjectLayout.module.scss';
import Layout from '../Layout/Layout';
import ProjectHeader from './ProjectHeader/ProjectHeader';
import useSiteMetadata from '../../hooks/use-site-metadata';
import ProjectNavigation from './ProjectNavigation/ProjectNavigation';
import ProjectContent from './ProjectContent/ProjectContent';
import normalizePathname from '../../utils/normalizer';

type Props = {
  pathname: string;
  description: ReactNode;
  children: ReactNode;
};

const ProjectLayout = ({pathname, description, children}: Props) => {
  const {projects} = useSiteMetadata();
  const normalizedPathname = normalizePathname(pathname);
  const item = projects.find(item => item.path === normalizedPathname);

  function render(item) {
    if (!item) {
      return (
        <h1>
          Something is wrong. Please contact administrator.
        </h1>
      );
    }
    return (
      <Layout pathname={normalizedPathname} incomingTitle={item.label}>
        <div className={styles.projectLayout}>
          <ProjectHeader title={item.label} description={description}/>
          <ProjectContent>
            {children}
          </ProjectContent>
          <ProjectNavigation pathname={item.path} projects={projects}/>
        </div>
      </Layout>
    );
  }

  return render(item);
};

export default ProjectLayout;
