// @flow

import React from 'react';
import ProjectLayout from '../components/ProjectLayout/ProjectLayout';
import LogosMarksContent from '../components/ProjectsContent/LogosMarksContent/LogosMarksContent';

const LogosMarksPage = ({location: {pathname}}) => {
  const description = () => (
    <div>
      Logos & Marks for products businesses and events.
    </div>
  );

  return (
    <ProjectLayout pathname={pathname} description={description()}>
      <LogosMarksContent/>
    </ProjectLayout>
  );
};

export default LogosMarksPage;
