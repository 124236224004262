// @flow strict
import React, { ReactNode } from 'react';
import styles from './ProjectHeader.module.scss';

type Props = {
  title: string;
  description: ReactNode;
};

const ProjectHeader = ({title, description}: Props) => {
  return (
    <div className={styles.projectHeader}>
      <h2>{title}</h2>
      <div className={styles["projectHeader__description"]}>
        {description}
      </div>
    </div>
  );
};

export default ProjectHeader;