// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import { getIcon } from '../../../../utils';
import { Icon } from '../../../Icon/Icon';
import styles from './ProjectNavigationLink.module.scss';

type Props = {
  url: string;
  iconName: boolean;
};

const ProjectNavigationLink = ({url, iconName}: Props) => {
  return (
    <div className={styles.projectNavigationLink}>
      <Link to={url}>
        <Icon icon={getIcon(iconName)}/>
      </Link>
    </div>
  );
};

export default ProjectNavigationLink;