// @flow strict
import React from 'react';
import styles from './ProjectNavigation.module.scss';
import ProjectNavigationLink from './ProjectNavigationLink/ProjectNavigationLink';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

type Props = {
  pathname: string;
  projects: any;
};

const projectLinks = (pathname, projects) => {
  let currIndex = projects.map(item => item.path).indexOf(pathname);
  let prevPath = currIndex === 0 ? undefined : projects[currIndex - 1].path;
  let nextPath = currIndex === (projects.length - 1) ? undefined : projects[currIndex + 1].path;

  return {
    prevPath,
    nextPath,
  };
};

const renderLink = (path, iconName) => {
  if (!path) return;
  return (
    <ProjectNavigationLink url={path} iconName={iconName}/>
  );
};

const declareClassName = (links) => {
  const prevPath = links.prevPath;
  const nextPath = links.nextPath;

  let className;
  if (prevPath && nextPath) {
    className = 'multiple';
  } else {
    className = 'center';
  }
  return 'projectNavigation__' + className;
};

const renderLinks = (links) => {
  return (
    <>
      {links.prevPath && renderLink(links.prevPath, 'arrowLeft')}
      {links.nextPath && renderLink(links.nextPath, 'arrowRight')}
    </>
  );
};

const ProjectNavigation = ({pathname, projects}: Props) => {
  const links = projectLinks(pathname, projects);
  const className = declareClassName(links);

  return (
    <div className={cx('projectNavigation', className)}>
      {renderLinks(links)}
    </div>
  );
};

export default ProjectNavigation;